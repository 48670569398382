body {
  margin: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.variableClass {
  color: rgb(0, 0, 0) !important;
  background: #ffa0a0;
  padding: 2px 16px !important;
  width: auto !important;
  display: inline-block !important;
  text-align: center !important;
  border-radius: 33px;
  position: relative;
  top: -1px;
  font-weight: 500;
  pointer-events: all !important;
  cursor: none !important;
  -moz-user-modify: read-only !important;
  -webkit-user-modify: read-only !important;
}
.editableEmailIcons {
  display: unset !important;
  pointer-events: none !important;
}

.scrollTest {
  overflow-y: auto;
  height: 90vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.scrollTest::-webkit-scrollbar {
  display: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  font-size: 20px;
  z-index: 100;
  height: 40px;
  /* color: darkgrey; */
}
.react-datepicker__input-container input {
  padding: 10px !important;
}

@media screen and (min-width: 150px) {
  .hsds-beacon .lmWZdz {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: 15% !important;
  }
}
@media screen and (min-width: 350px) {
  .hsds-beacon .lmWZdz {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: 12% !important;
  }
}
@media screen and (min-width: 600px) {
  .hsds-beacon .lmWZdz {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: 10% !important;
  }
}
@media screen and (min-width: 890px) {
  .hsds-beacon .lmWZdz {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: 9% !important;
  }
}
@media screen and (min-width: 1300px) {
  .hsds-beacon .lmWZdz {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: 4% !important;
  }
}
@media screen and (min-width: 1500px) {
  .hsds-beacon .lmWZdz {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    margin-bottom: 2% !important;
  }
}

@keyframes loader {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
